<template>
  <div class="upload-container">
    <a-button :style="{background:color,borderColor:color}" icon="el-icon-upload"  type="primary"
               @click=" dialogVisible=true">
      插入图片
    </a-button>
      <a-modal v-model="dialogVisible" @ok="handleSubmit">
      <a-upload
        :multiple="true"
        :default-file-list="fileList"
        :show-file-list="true"
        :remove="handleRemove"
        @change ="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        :action="action"
        :headers="{token:$store.getters.token}"
        name="pic"
        list-type="picture-card"
        accept="image/*"
      >
        <a-button type="primary">
          点击插入
        </a-button>
      </a-upload>
<!--      <a-button @click="dialogVisible = false">
        取消
      </a-button>
      <a-button type="primary" @click="handleSubmit">
        确定插入
      </a-button>-->
      </a-modal>
  </div>
</template>

<script>
let {upload_url} = require('../../../settings')

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      action: upload_url
    }
  },
  methods: {
    checkAllSuccess() {
      //console.log("A")
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit() {
      //console.log("B")
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!')
        return
      }
      this.$emit('successCBK', arr)
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess({file,fileList,event}) {
      //console.log("C")
      //console.log(file)
      //console.log(fileList)
      //console.log(event)
      if(file.status==='done'){
        const uid = file.uid
        const objKeyArr = Object.keys(this.listObj)
        for (let i = 0, len = objKeyArr.length; i < len; i++) {
          if (this.listObj[objKeyArr[i]].uid === uid) {
            this.listObj[objKeyArr[i]].url =file.response.data.url
            this.listObj[objKeyArr[i]].hasSuccess = true
            return
          }
        }
      }
    },
    handleRemove(file) {
      //console.log("D")
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    beforeUpload(file) {
      //console.log("F")
      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      this.listObj[fileName] = {}
      return new Promise((resolve, reject) => {
        //console.log(reject)
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function () {
          _self.listObj[fileName] = {hasSuccess: false, uid: file.uid, width: this.width, height: this.height}
        }
        resolve(true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;

  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>
