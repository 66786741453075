module.exports = {

  title: 'Vue Admin Template',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,

  oss_url: 'https://com-datalent.oss-cn-hangzhou.aliyuncs.com/',

  upload_url: process.env.VUE_APP_BASE_API + '/upload/ossUpload',
}
